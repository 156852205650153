*,
*::before,
*::after
    box-sizing: border-box

body,
h1,
h2,
h3,
h4,
p,
ul,
li
    margin: 0

body
    scroll-behavior: smooth
    text-rendering: optimizeSpeed

ul, ol
    list-style: none
    padding: 0

// a:not([class])
a
    text-decoration-skip-ink: auto
    text-decoration: none

input,
button
    font: inherit
    margin: 0

button
    cursor: pointer
    &:focus
        outline: none

@media (prefers-reduced-motion: reduce)
    *
        animation-duration: 0.01ms !important
        animation-iteration-count: 1 !important
        transition-duration: 0.01ms !important
        scroll-behavior: auto !important

// credits: https://medium.com/@stasonmars
