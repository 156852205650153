.topbar
    background-color: #000
    color: #fff
    text-align: center
    padding: 0.5rem 0

.wrapper
    position: relative

.promo
    font-size: 15px
    font-weight: 300

.utils
    position: absolute
    top: 50%
    right: 0
    transform: translateY(-50%)
    align-items: center

.button
    color: #fff
    background: transparent
    border: none
    align-items: center
    padding: 6px
    span
        font-size: 14px
        margin-left: 4px
        margin-top: 3px
    sup
        padding: 1px
        margin-bottom: 4px

@media screen and (max-width: 568px)
    .topbar
        text-align-last: left
        padding: 0.5rem 0
    .promo, .button
        font-size: 14px
    .button
        font-size: 14px
        padding: 4px
        font-weight: 300
