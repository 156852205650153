@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false )
	@font-face
		font-family: $font-family
		font-weight: $weight
		font-style: $style
		src: url('#{$file-path}.eot')
		src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype')

+font-face("Helvetica", "../fonts/HelveticaRegular/HelveticaRegular")
+font-face("Helvetica", "../fonts/HelveticaBold/HelveticaBold", bold)
+font-face("Helvetica", "../fonts/HelveticaLight/HelveticaLight", 300)
+font-face("ProximaNova", "../fonts/ProximaNovaRegular/ProximaNovaRegular")
+font-face("ProximaNova", "../fonts/ProximaNovaBold/ProximaNovaBold", bold)
+font-face("ProximaNova", "../fonts/ProximaNovaThin/ProximaNovaThin", 100)
+font-face("ProximaNova", "../fonts/ProximaNovaLight/ProximaNovaLight", 300)
