.footer
    background-color: #f8f8f8
    padding: 2rem 0
    h5
        font-weight: bold

.email
    display: flex
    padding: 0.5rem 0
    input
        width: 100%
        padding: 8px 12px
        background-color: #fff
        border: none
        border: 1px solid #3F4A3C33
        border-radius: 2px 0 0 2px
    button
        white-space: nowrap

.bottom
    padding: 1rem 0
